import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"warehouse"}},[_c('ws-app-bar',{attrs:{"dark":""}}),_c(VSheet,{staticClass:"d-flex flex-column justify-space-between",attrs:{"color":"ws-black","dark":"","elevation":"0"}},[_c(VImg,{staticClass:"flex-grow section-md",attrs:{"eager":"","aspect-ratio":"4","position":_vm.$vuetify.breakpoint.mdAndUp ? '100% 55%' : 'center center',"src":require('../assets/warehouse/warehouse-banner.webp'),"xgradient":"to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)","gradient":_vm.$vuetify.breakpoint.mdAndUp
          ? 'to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)'
          : 'to right, rgba(12 ,25 ,34, .7),  rgba(12 ,25 ,34, .7)'}},[_c(VContainer,{staticClass:"py-14"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{staticClass:"px-5 px-lg-0",attrs:{"cols":"12","md":"8","lg":"5"}},[_c('h1',{staticClass:"hidden-sm-and-down"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Optimizing your space "),_c('br'),_vm._v("for extra revenue. ")])],1),_c('h1',{staticClass:"hidden-md-and-up"},[_vm._v(" Optimizing your space for extra revenue. ")]),_c('p',{staticClass:"body-1 font-weight-bold my-6 hidden-sm-and-down"},[_vm._v(" Do you have additional storage space that you are not utilizing? Join our network of warehousing service providers and create an additional income stream for as long as you need to. ")]),_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"light":"","color":"white","href":"mailto:connect@westore.ai"},on:{"click":function($event){return _vm.logGAEvent(
                    'Service Provider Page',
                    'contact the team',
                    "contact the team email"
                  )}}},[_vm._v(" Join us now ")])],1)])],1)],1)],1)],1),_c('section',{staticClass:"ws-black white--text section-lg"},[_c(VContainer,[_c('h2',{staticClass:"text-center"},[_vm._v("How it Works?")]),_c('br'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2","lg":"6","offset-lg":"3"}},[_c('p',{staticClass:"body-1 font-weight-bold text-center"},[_vm._v(" We’re behind you all the way. Everything’s handled through the WeStore Platform – centralising workflows for scheduling pickups through our logistics network, managing inventory, and processing legal agreements and billing. So we do the heavy lifting, you enjoy the extra business. ")])]),_vm._l((_vm.howItWorks),function(item){return _c(VCol,{key:item.title,staticClass:"text-center px-6",attrs:{"cols":"12","md":"4"}},[_c(VImg,{staticClass:"my-5",attrs:{"eager":"","contain":"","src":item.numberUrl,"height":"60"}}),_c(VImg,{staticClass:"my-4",attrs:{"eager":"","contain":"","src":item.iconUrl,"height":"90"}}),_c('p',{staticClass:"body-1 font-weight-bold text-center",domProps:{"textContent":_vm._s(item.description)}})],1)}),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ws-black--text",attrs:{"color":"accent","href":"mailto:connect@westore.ai"},on:{"click":function($event){return _vm.logGAEvent(
                'Service Provider Page',
                'contact the team',
                "contact the team email"
              )}}},[_vm._v(" Join us now ")])],1)],2)],1)],1),_c('section',{staticClass:"section-lg"},[_c(VContainer,[_c('h2',{staticClass:"text-center benefits-title nerrow-lines mb-12"},[_vm._v(" The Benefits ")]),_c(VRow,_vm._l((_vm.storageDelivery),function(sd,i){return _c(VCol,{key:i,staticClass:"pa-0 px-5 mb-5",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"benfits-card"},[_c(VImg,{attrs:{"eager":"","src":sd.imageUrl}}),_c('p',{staticClass:"body-1 font-weight-bold px-2 text-center mt-4",domProps:{"innerHTML":_vm._s(sd.description)}}),_c(VBtn,{staticClass:"mb-4",attrs:{"small":"","color":"accent","text":"","href":"mailto:connect@westore.ai"},on:{"click":function($event){return _vm.logGAEvent(
                  'Service Provider Page',
                  'contact the team',
                  "contact the team email"
                )}}},[_vm._v(" Let's Chat "),_c(VIcon,{staticClass:"mt-1",attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1)])}),1)],1)],1),_c('ws-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }