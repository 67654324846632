







































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "ServiceProvider",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.ServiceProvider.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.ServiceProvider.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  methods: {
    showHideContactsCustom() {
      if (this.globals.whatsappPopupVisible) {
        this.logGAEvent("Let's Chat", "Whatsapp open", `Warehouse Page`);
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
  },
  data: () => ({
    howItWorks: [
      {
        iconUrl: require("../assets/warehouse/hiw-01.png"),
        numberUrl: require("../assets/new-site/warehouses/number1.png"),
        description:
          "Register to join our team and go through a vetting process",
      },
      {
        iconUrl: require("../assets/warehouse/hiw-02.png"),
        numberUrl: require("../assets/new-site/warehouses/number2.png"),
        description:
          "As soon as we can confirm that your quality standards are up to par, you can rent out as much space as you want to, for however long you want to",
      },
      {
        iconUrl: require("../assets/warehouse/hiw-03.png"),
        numberUrl: require("../assets/new-site/warehouses/number3.png"),
        description: "Receive timely, monthly payments through us",
      },
    ],
    storageDelivery: [
      {
        description: "Be flexible with no long-term <br/> commitments.",
        imageUrl: require("../assets/new-site/warehouses/ben-01.jpg"),
      },
      {
        description: "Receive additional earnings <br/> on a monthly basis.",
        imageUrl: require("../assets/new-site/warehouses/ben-02.jpg"),
      },
      {
        description: "Optimize<br/>your resources.",
        imageUrl: require("../assets/new-site/warehouses/ben-003.jpg"),
      },
    ],
  }),
  computed: {
    types() {
      return types;
    },
  },
});
